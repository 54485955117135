import { useEffect, useState } from 'react';
//import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { Grow, Box, Grid, Typography, TextField, Button, Divider, ThemeProvider, Autocomplete, useMediaQuery } from '@mui/material'
import { getBlago } from '../actions/blago';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { IoMdArrowBack } from 'react-icons/io'
import { CPaper, customColors } from '../styles/themes'
import Modal from '@mui/material/Modal';
import React, { useRef, useImperativeHandle } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createBlago } from '../actions/blago'

const IzberiBlago = React.forwardRef((props, ref) => {
  const size = useMediaQuery('(max-width:600px)'); //phone 
  // let navigate = useNavigate();
  const regProps = ['naziv', 'enota', 'cena', 'stopnjaDDV', 'opis']
  const { storitev, setStoritev, storitve, setStoritve, blago, setBlago } = useStateContext();
  //const [selectedStoritev, setSelectedStoritev] = useState({ id: storitve.length, naziv: '', kolicina: '', enota: '', cena: '', stopnjaDDV: '', opis: '' });
  const paperStyle = { width: size ? '90%' : '500px', padding: '1.5rem' };

  const [regItemChanged, setRegItemChanged] = useState(false);
  const [index, setIndex] = useState(-1);
  const [regSelectBoxVal, setRegSelectBoxVal] = useState(null);

  const clear = () => {

    /* console.log(index);
     if (index >= 0) {
       console.log(storitve[index]);
       // setSelectedStoritev(storitve[id]);
       console.log(storitve)
       setStoritev(storitve[index]);
     }
     else*/
    //let ids = storitve.reduce((max, obj) => obj.id > max ? obj.id : max, -1) + 1; //dobimo največji trenutni id + 1. ta -1 je v primeru da je prazen. 
    //console.log('clear new id = ' + ids)
    //resetFormErrors()
    //id: ids,
    setStoritev({ naziv: '', kolicina: '1', enota: '', cena: '', stopnjaDDV: '', opis: '', cenaSkupaj: '' });
    //   setSelectedStoritev({ id: storitve.length, naziv: '', kolicina: '', enota: '', cena: '', stopnjaDDV: '', opis: '' });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


  }

  const checkValidity = () => {
    const myForm = document.getElementById('formBlago');
    const formInputs = myForm.querySelectorAll('input');

    let formValid = true;
    let fe = { ...formErrors }

    formInputs.forEach(input => {

      if (input.name && input.name.length > 0) {
        let v = input.checkValidity()
        fe[input.name] = !v
        if (!v) formValid = false;
      }
    });
    setFormErrors(fe);

    return formValid
  }


  const potrdi = () => {

    //console.log(document.getElementById('formBlago').checkValidity())

    //console.log('dddfd handleSubmit')
    // e.preventDefault();
    //setStoritev({ ...storitev, id: storitve.length });

    if (!checkValidity()) return

    if (index >= 0) {
      const updatedStoritve = [...storitve];
      updatedStoritve[index] = storitev;
      setStoritve(updatedStoritve);
    }
    else
      setStoritve([...storitve, storitev]);

    if (index == -1 && checked) {

      const newObj = regProps.reduce((acc, prop) => {
        if (storitev.hasOwnProperty(prop)) {
          acc[prop] = storitev[prop];
        }
        return acc;
      }, {});

      if (!regSelectBoxVal) {
        //dodaj V REGISTER
        console.log('dodaj V REGISTER')
        console.log(newObj)
        createBlago(newObj).then(() => {
          getBlago(setBlago);
        })

      }
      else if (regItemChanged) {
        //posodobi REGISTER
        console.log('posodobi REGISTER')
        newObj.id = regSelectBoxVal._id
        console.log(newObj)
        createBlago(newObj).then(() => {
          getBlago(setBlago);
        })


      }
    }


    handleClose()
    /*  if (typeof window !== 'undefined') {
        window.history.back();
      }*/
    //navigate('/izdaja');
  }

  const deleteThisRow = () => {
    if (index >= 0) {
      // console.log(index)
      const updatedStoritve = storitve.filter((_, i) => i != index);
      //console.log(updatedStoritve)
      setStoritve(updatedStoritve);
    }

    handleClose()
    //navigate('/izdaja');
  }

  /* useEffect(() => {
     //console.log('[storitve, storitev]')
     let ids = storitve.reduce((max, obj) => obj.id > max ? obj.id : max, -1) + 1; //dobimo največji trenutni id + 1. ta -1 je v primeru da je prazen. 
     if (index >= 0)
       setStoritev({ ...storitev, cenaSkupaj: String((Number(storitev.cena.replace(',', '.')) * Number(storitev.kolicina.replace(',', '.'))).toFixed(2)).replace('.', ',') });
     else
       setStoritev({ ...storitev, id: ids, cenaSkupaj: String((Number(storitev.cena.replace(',', '.')) * Number(storitev.kolicina.replace(',', '.'))).toFixed(2)).replace('.', ',') });
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [storitev]);
 */

  /*useEffect(() => {
    
    //console.log('id2:');
    //console.log(id)
  }, id);*/


  /*useEffect(() => {
    getBlago(setBlago);
    //console.log('b1')
  }, [setBlago]);*/

  useEffect(() => {
    getBlago(setBlago);
    //console.log('b2')
    //console.log(id)
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillFromSelectBoxChange = (o) => {

    setRegSelectBoxVal(o)
    //setAddUpdateReg(false)

    if (o === null) {
      clear();
      resetFormErrors()
    }
    else {
      setStoritev({ ...o, kolicina: storitev.kolicina });
      resetFormErrors()
    }
  }

  /* useEffect(() => {
     console.log('ghh')
     console.log(selectedStoritev)
       if(!selectedStoritev.kolicina) setStoritev({ ...selectedStoritev, kolicina: '1' });
   }, [selectedStoritev]); //, setStoritev
 */

  useEffect(() => {
    //console.log(storitev)
    if (regItemChanged)
      setRegItemChanged(false)
    if (regSelectBoxVal) {
      for (let i = 0; i < regProps.length; i++) {
        const prop = regProps[i];
        // console.log(regSelectBoxVal[prop])
        if (regSelectBoxVal[prop] != storitev[prop]) {
          setRegItemChanged(true)
          break
        }
      };
    }
  }, [storitev]); //, setStoritev

  const [open, setOpen] = React.useState(false);
  const handleOpen = (i) => {

    setIndex(i)
    //getBlago(setBlago);
    resetFormErrors()
    setRegSelectBoxVal(false)

    if (i >= 0)
      setStoritev(storitve[i]);
    else
      clear();

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen
  }));


  const [checked, setChecked] = React.useState(true);
  const handleChChange = (event) => {
    setChecked(event.target.checked);
  };


  /*setTimeout(function(){
    handleOpen()
  },2000);*/
  const mojstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size ? '90%' : '500px',

    border: '0',
    padding: '1.8rem',
    outline: 'none'
  };

  const resetFormErrors = () => {
    setFormErrors({
      naziv: false,
      kolicina: false,
      enota: false,
      cena: false,
      stopnjaDDV: false,
    })
  }
  const [formErrors, setFormErrors] = useState({
    naziv: false,
    kolicina: false,
    enota: false,
    cena: false,
    stopnjaDDV: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name) {
      setFormErrors({
        ...formErrors,
        [name]: !event.target.checkValidity()
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >

      <CPaper elevation={24} sx={mojstyle}>
        <form id="formBlago" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item sm={12} xs={12}>
              <Typography variant="button" fontWeight={600} color={'#969696'} fontSize={'0.75rem'} letterSpacing={'0.7px'}>Izberi blago/storitev</Typography>
            </Grid>



            <Grid item sm={12} xs={12} style={{ marginBottom: '10px', display: index >= 0 ? 'none' : 'block' }}>
              <Autocomplete options={blago} getOptionLabel={option => option.naziv} renderInput={params => <TextField {...params} label="Izberi iz registra" variant="standard" fullWidth />} onChange={(event, value) => { fillFromSelectBoxChange(value) }} />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField variant='standard' size='small' label="Naziv" error={formErrors.naziv} name="naziv" onChange={handleChange} fullWidth value={storitev.naziv} onInput={(e) => setStoritev({ ...storitev, naziv: e.target.value })} required />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextField variant='standard' size='small' label="Količina" error={formErrors.kolicina} name="kolicina" inputProps={{ pattern: "^\\d+([\\.\\,]\\d{1,4})?$" }} onChange={handleChange} fullWidth value={storitev.kolicina} onInput={(e) => setStoritev({ ...storitev, kolicina: e.target.value })} required />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField variant='standard' size='small' label="Enota" error={formErrors.enota} name="enota" onChange={handleChange} value={storitev.enota} fullWidth onInput={(e) => setStoritev({ ...storitev, enota: e.target.value })} required />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField variant='standard' size='small' label="Cena" error={formErrors.cena} name="cena" inputProps={{ pattern: "^\\d+([\\.\\,]\\d{1,4})?$" }} onChange={handleChange} fullWidth value={storitev.cena} onInput={(e) => setStoritev({ ...storitev, cena: e.target.value })} required />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField variant='standard' size='small' label="Stopnja DDV" error={formErrors.stopnjaDDV} name="stopnjaDDV" inputProps={{ pattern: "^\\d+([\\.\\,]\\d{1,2})?$" }} onChange={handleChange} fullWidth value={storitev.stopnjaDDV} onInput={(e) => setStoritev({ ...storitev, stopnjaDDV: e.target.value })} required />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField variant='standard' size='small' label="Opis" fullWidth value={storitev.opis} multiline minRows={2} onChange={(e) => setStoritev({ ...storitev, opis: e.target.value })} />
            </Grid>
          </Grid>
        </form>
        <div className="bottomBtns">
          <Button form="form" variant="contained" color="primary" size="medium" onClick={potrdi} >{index >= 0 ? 'Shrani' : 'Potrdi'}</Button>
          <Button form="form" style={{ display: index >= 0 ? 'flex' : 'none' }} variant="contained" color="secondary" size="medium" onClick={deleteThisRow} >Odstrani</Button>

          <FormControlLabel style={{ color: '#6D6D6D', display: index == -1 && (!regSelectBoxVal || regSelectBoxVal && regItemChanged) ? 'inline-flex' : 'none' }} labelPlacement="start" control={<Checkbox size="medium" checked={checked} onChange={handleChChange} />} label={regSelectBoxVal ? "Posodobi register" : "Dodaj v register"} />
        </div>
      </CPaper>

    </Modal>
  )
})

export default IzberiBlago

/*
 <Button variant="contained" color="secondary" size="medium" onClick={() => navigate('/')}>Nazaj</Button>
  */
/*
 <Grid item sm={12} xs={12}>
              <Button variant="text" color="primary" size="small" startIcon={<AiOutlinePlus />} onClick={() => navigate("../dodaj-blago")}>Dodaj novo</Button>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor: '#ADD8E6' }} />
            </Grid>
*/