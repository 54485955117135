import React, { useState, useEffect } from 'react'
import { Grow, Box, Grid, Typography, TextField, Button, ThemeProvider, useMediaQuery } from '@mui/material'
import { CPaper, customColors } from '../styles/themes'
import { IoMdArrowBack } from 'react-icons/io'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { createPartner } from '../actions/partnerji'

const Dodajblago = () => {
  const size = useMediaQuery('(max-width:600px)'); //phone 
  const paperStyle = { width: size ? '90%' : '70%', padding: '1.5rem' };
  const { partnerji, setPartnerji } = useStateContext();
  const [sifra, setSifra] = useState(partnerji.length);
  const [trenutniPartner, setTrenutniPartner] = useState({ sifra: sifra, naziv: '', naslov: '', mesto:'', posta:'', drzava:'', oznakaDrzave:'', TRRracun:'', davcnaStevilka: '' });
  let navigate = useNavigate();
  const clear = () => setTrenutniPartner({  sifra: sifra, naziv: '', naslov: '', mesto:'', posta:'', drzava:'', oznakaDrzave:'', TRRracun:'', davcnaStevilka: '' });

  
  const handleSubmit = (e) =>{
    e.preventDefault();
    createPartner(trenutniPartner);
    setTrenutniPartner({...trenutniPartner, sifra: sifra});
    setPartnerji([...partnerji, trenutniPartner]);
    navigate('/partner');
  }
  useEffect(() => {
    setSifra(partnerji.length);
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Grow in>
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop:'8rem' }}>
        <CPaper elevation={24} sx={paperStyle}>
          <form id="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item sm={12} xs={12}>
                <Typography variant="button" fontWeight={600} >Dodaj Partnerja</Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Šifra" value={sifra} inputProps={{ readOnly: true, style:{fontWeight:'600', marginLeft:'10px'} }} fullWidth required />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Naziv" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, naziv: e.target.value}) } /> 
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Naslov" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, naslov: e.target.value}) } /> 
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextField variant='standard' size='small' label="Mesto" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, mesto: e.target.value}) } />
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextField variant='standard' size='small' label="Poštna številka" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, posta: e.target.value}) } />
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextField variant='standard' size='small' label="Država" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, drzava: e.target.value}) } />
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextField variant='standard' size='small' label="Oznaka države" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, oznakaDrzave: e.target.value}) } />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField variant='standard' size='small' label="TRR račun" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, TRRracun: e.target.value}) } />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField variant='standard' size='small' label="Davčna Številka" fullWidth required onChange={(e) => setTrenutniPartner({...trenutniPartner, davcnaStevilka: e.target.value}) } />
              </Grid>
            </Grid>
          </form>
          <div className="bottomBtns">
            <ThemeProvider theme={customColors} >
              <Button form="form" variant="contained" color="primary" size="small" type="submit" startIcon={<AiOutlinePlus />}>Shrani</Button>
              <Button variant="contained" color="secondary" size="small" startIcon={<IoMdArrowBack />} onClick={() => navigate(-1)}>Nazaj</Button>
            </ThemeProvider>
          </div>
        </CPaper>
      </Box>
    </Grow>
  )
}

export default Dodajblago