import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { Grow, Box, Grid, Typography, TextField, Button, Divider, ThemeProvider, Autocomplete, useMediaQuery } from '@mui/material'
import { getPartnerji } from '../actions/partnerji';
import { AiOutlinePlus } from 'react-icons/ai'
import { IoMdArrowBack } from 'react-icons/io'
import React from 'react'
import { CPaper, customColors } from '../styles/themes'

const IzberiBlago = () => {
  const size = useMediaQuery('(max-width:600px)'); //phone 
  let navigate = useNavigate();
  const { partnerji, setPartnerji, setSelectedPartner } = useStateContext();
  const paperStyle = { width: size ? '90%' : '70%', padding: '1.5rem' };

  const handleSubmit = () => {
    navigate('/izdaja');
  }

  useEffect(() => {
    getPartnerji(setPartnerji);
  }, [setPartnerji]);

  useEffect(() => { 
    getPartnerji(setPartnerji);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grow in>
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop:'8rem' }}>
        <CPaper elevation={24} sx={paperStyle}>
          <form id="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item sm={12} xs={12}>
                <Typography variant="button" fontWeight={600} >Izberi Partnerja</Typography>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button variant="text" color="primary" size="small" startIcon={<AiOutlinePlus />}  onClick={() => navigate("../dodaj-partnerja") }>Dodaj novega partnerja</Button>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor:'#ADD8E6'}} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Autocomplete options={partnerji} getOptionLabel={option => option.naziv+' [ '+option.davcnaStevilka+' ]'} renderInput={params => <TextField {...params} label="Izberi partnerja" variant="standard" fullWidth />} onChange={(event, value) => { if(value !==null)setSelectedPartner(value)}} />
              </Grid>
            </Grid>
          </form>
          <div className="bottomBtns">
            <ThemeProvider theme={customColors} >
              <Button form="form" variant="contained" color="primary" size="small" type="submit" startIcon={<AiOutlinePlus />}>Potrdi</Button>
              <Button variant="contained" color="secondary" size="small" startIcon={<IoMdArrowBack />} onClick={() => navigate('/')}>Nazaj</Button>
            </ThemeProvider>
          </div>
        </CPaper>
      </Box>
    </Grow>
  )
}

export default IzberiBlago