import * as api from '../api/index.js';

export const getRacuni = async () => {
    try {
        const { data } = await api.getRacuni();
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const getRacuniTop = async (nr, type) => {
    try {
        const { data } = await api.getRacuniTop(nr, type);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const getRacun = async (id) => {
    try {
        const { data } = await api.getRacun(id);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteRacun = async (id) => {
    try {
        const { data } = await api.deleteRacun(id);
        //console.log(data)
        return data;
    } catch (error) {
        return {message: error.message}
        console.log(error);
    }
}

export const createRacun = async (newRacun) => {
    try {
        const { data } = await api.createRacun(newRacun);
        return data;
    } catch (error) {
        console.log(error);
        return { message: error }
    }
}

export const getRacuniCount = async (year, setNumOfRacun) => {
    try {
        const { data } = await api.getRacuniCount(year);
        setNumOfRacun(data);
    } catch (error) {
        console.log(error);
    }
}

export const getSetNextInvoiceNr = async (year, setFunc) => {
    try {
        const { data } = await api.getRacuniCount(year);
       // console.log(data)
       // console.log( year.toString().slice(-2) + data.toString().padStart(4, '0'));
        setFunc(year.toString().slice(-2) + '-' + data.toString().padStart(4, '0'));
    } catch (error) {
        console.log(error);
    }
}

export const generatePdf = async (id) => {
    try {
        await api.genPdf(id);
    } catch (error) {
        console.log(error);
    }
}