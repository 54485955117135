export const formatter = new Intl.NumberFormat('sl-SI', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const calculateTotal = (storitve, setTotalBrezDDV, storitev, setTotalBrezDDVForEveryDDV, setTotalDDVs) => {
  let Globaltotal = 0;
  let diffDdvs = [];
  let totalForEveryDDV = [];
  storitve.forEach(storitev => {
    let dobljenDDV = Number(storitev.stopnjaDDV.replace(',', '.')).toFixed(2);
    if (!diffDdvs.includes(dobljenDDV)) {
      diffDdvs.push(dobljenDDV);
    }
  });
  diffDdvs.forEach((ddv) => {
    let ddvNumber = Number(ddv).toFixed(2);
    let total = 0;
    storitve.forEach(storitev => {
      let dobljenDDV = Number(storitev.stopnjaDDV.replace(',', '.')).toFixed(2);
      if (ddvNumber === dobljenDDV) {
        let toBeAdded = Number(storitev.cena.replace('.','').replace(',', '.')) * Number(storitev.kolicina);
        total += toBeAdded;
      }
    })
    totalForEveryDDV.push(total);
  });

  totalForEveryDDV.forEach((total) => {
    Globaltotal += total;
  });

  let numOfTotal = Number(Globaltotal).toFixed(2);
  setTotalBrezDDVForEveryDDV(totalForEveryDDV);
  setTotalDDVs(diffDdvs);
  setTotalBrezDDV(String(formatter.format(numOfTotal)));
}

export const calculateDdvs = (totalBrezDDVForEveryDDV, totalDDVs, setTotalForEveryDdv, storitve) => {
  if(!storitve.length > 0 || !totalBrezDDVForEveryDDV > 0 || !totalDDVs > 0) return;
  let total;
  let totalForEveryDdv = [];
  for(let i = 0; i < totalBrezDDVForEveryDDV.length; i++) {
    let ddvNumber = Number(totalDDVs[i].replace(',', '.'));
    let brezDDv = Number(totalBrezDDVForEveryDDV[i]);
    let ddv = (brezDDv * ddvNumber) / 100;
    total = brezDDv + ddv;
    if(totalForEveryDdv.length === 0){
      totalForEveryDdv.push({ ddv: ddvNumber, total, brezDDv });
    } else {
      let hasDDV = false;
      totalForEveryDdv.forEach((total) => {
        if(total.ddv === ddvNumber) {
          hasDDV = true;
        }
      })
      if(!hasDDV) {
        totalForEveryDdv.push({ddv: ddvNumber, total, brezDDv });
      }
    };
    setTotalForEveryDdv(totalForEveryDdv);
  }
}

export const calculateTotalPrice = (setTotalNumber, totalForEveryDdv) => {
//console.log('calculateTotalPrice')
  let total = 0;
  totalForEveryDdv.forEach((totalForDdv) => {
    total += totalForDdv.total;
  })
  let numOfTotal = Number(total).toFixed(2);
//console.log(numOfTotal)

  setTotalNumber(String(formatter.format(numOfTotal)));
}

export const calcNumOfZeros = (numOfRacun) => {
    if(numOfRacun < 10)
        return `000${numOfRacun}`;
    else if(numOfRacun < 100)
        return `00${numOfRacun}`;
    else if(numOfRacun < 1000)
        return `0${numOfRacun}`;
    else
        return `${numOfRacun}`;
}

export const checkOznacene = (selected, alreadySent) => {
  let hasAlreadySent = false;
  let selectedButSent = [];

  selected.forEach(id => {
    if(alreadySent.includes(id)) {
      hasAlreadySent = true;
      selectedButSent.push(id);
    }
  })

  return { hasAlreadySent, selectedButSent };
}