import { styled, createTheme } from '@mui/material/styles'
import { Paper } from '@mui/material'
/**
window.addEventListener('resize', () => { //mby not the best idea
    isMobile()&&window.location.reload();
    
})
*/
const isMobile = () => {
    return window.innerWidth < 600
}

export const themeMain = createTheme({
    typography: {
        
      fontFamily: [
        'Montserrat',
        'sans-serif',
      ].join(','),
      fontWeightRegular: 500,
      fontSize: 13
    },
    components: {
        MuiInputLabel: {
          defaultProps: {
            sx: {
              fontWeight: 600,
              transform: 'translate(0, -1.5px) scale(0.8)'
            },
          },
        },
    },
    overline:{
        fontSize: 5
    },
    subtitle1:{
        fontSize: 5
    },
  });

export const themeBold = createTheme({
    typography: {
        overline: {
            fontWeight: 600,
        },
        button: {
            fontWeight: 600,
        },
    }
    
});

export const customColors = createTheme({
    palette: {
        primary: {
            main: '#0288d1',
        },
        secondary: {
            main: '#000',
        },
    }
});

export const PartenerTexts = createTheme({
    typography: {
        body1: {
            fontWeight: 600,
            fontSize: isMobile() ? '12px' : '14px',
        },
        body2: {
            fontSize:'14px'
        }
    }
})


export const CPaper = styled(Paper)(({ theme }) => ({
}));
