import React, { useState } from 'react'
import { Grow, Box, Grid, Typography, TextField, Button, Divider, Autocomplete, ThemeProvider } from '@mui/material'
import { CPaper, customColors } from '../styles/themes';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';
import { sendMail } from '../actions/mail';
import toast, { Toaster } from 'react-hot-toast';

import Popup from '../components/Popup';

import '../styles/styles.css'

const SuccessSummary = ({ id }) => {
    const navigate = useNavigate();
    const { finnalRacun } = useStateContext();
    const { stRacuna, stNarocila, partner, datumIzdaje, datumOprStoritve, rokPlacila, storitve, skupajZaPlacilo, skupajBrezDDV } = finnalRacun;
    const [popupStoritev, setPopupStoritev] = useState();
    const [isSelected, setIsSelected] = useState(false);

    window.addEventListener('load', () => {
        window.location.href = '/izdaja';
      })

    const handleSendMail = async () => {
        const recipient = prompt('Vnesite e-mail naslov prejemnika:');

        const res = await sendMail(id, recipient);
        if(res.data.success === true) {
            toast.success('E-mail je bil poslan! Preverite tudi spam predal.');
        }
        else {
            toast.error('Prišlo je do napake pri pošiljanju e-maila.');
        }
    }

    const handleChange = (event,value) => {
        if(event.target.tagName !== 'svg' && value.cena !== null ){
            setPopupStoritev(value)
            setIsSelected(true)
        }
    }

    return (
    <Grow in>
        <Box component="div" sx={{ display:'flex', alignItems:'flex-start', justifyContent:'center', marginTop:'8rem' }}>
            <Toaster />
            <CPaper elevation={24} sx={{ width:'70%', padding:'1.5rem' }}>
                <Grid container spacing={2} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h4" align="center" >Uspešno dodano</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Št Računa:</Typography>
                            <Typography variant="button" fontWeight={600}>{stRacuna}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Št Naročila:</Typography>
                            <Typography variant="button" fontWeight={600}>{stNarocila}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Davčna Št. Partnerja:</Typography>
                            <Typography variant="button" fontWeight={600}>{partner.davcnaStevilka}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Datum Izdaje:</Typography>
                            <Typography variant="button" fontWeight={600}>{datumIzdaje}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Datum Opr. Storitve:</Typography>
                            <Typography variant="button" fontWeight={600}>{datumOprStoritve}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Rok plačila:</Typography>
                            <Typography variant="button" fontWeight={600}>{rokPlacila}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Storitve:</Typography>
                            <Autocomplete options={storitve} getOptionLabel={(option) => option.naziv + ' ['+ option.opis + ']' } renderInput={(params) => <TextField {...params} label="Storitve" variant="standard" size="small" sx={{width:'50vw'}}/>} onChange={(event,value) => {handleChange(event,value)}}  />
                        </div>
                        {isSelected && <Popup storitev={popupStoritev} setIsSelected={setIsSelected}/>}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Cena brez DDV:</Typography>
                            <Typography variant="button" fontWeight={600}>{skupajBrezDDV}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <div className="displayDiv">
                            <Typography variant="subtitle1" fontWeight={600}>Cena z DDV:</Typography>
                            <Typography variant="button" fontWeight={600}>{skupajZaPlacilo}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor:'#ADD8E6'}} />
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor:'#ADD8E6', marginTop:'5px'}} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <ThemeProvider theme={customColors} >
                        <div className='bottomBtns'>
                            <Button variant="contained" color="primary" onClick={handleSendMail}>Pošlji na mail</Button>
                            <Button variant="contained" color="secondary" onClick={() => navigate('/pregled')}>Seznam računov</Button>
                            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>Nov Račun</Button>
                        </div>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </CPaper>
        </Box>
    </Grow>
  )
}

export default SuccessSummary