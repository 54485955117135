import * as api from '../api/index'

export const getNaiData = async() => {
    const { data } = await api.getNaiData();
    const naiData = data.data;
    return naiData;
}

export const getNaiDataById = async(ids, prejemnik, createdRacunID) => {
    const { data } = await api.sendNaiIds(ids, prejemnik, createdRacunID);
    const status = data.status;
    if(status === 'success') {
        alert('XML file ustvarjen in poslan');
    } else {
        alert('Napaka, poskusite ponovno');
    }
}

export const getDataByDate = async(from, to) => {
    const { data } = await api.getDataByDate(from, to);
    const naiData = data.data;
    return naiData;
}

export const getAlreadySent = async() => {
    const { data } = await api.alreadySent();
    const alreadySent = data.data;
    const alreadySentIds = alreadySent.map((alreadySent) => {
        return alreadySent.id_cenitev;
    })
    
    return alreadySentIds;
}