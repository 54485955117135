import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate, HashRouter } from 'react-router-dom'
import { Container } from '@mui/material'
import { verifyUser } from './actions/auth'
import { useStateContext } from './context/ContextProvider';

import Izdaja from './pages/Izdaja'
import DodajBlago from './pages/Dodajblago'
import IzberiBlago from './pages/IzberiBlago'
import SuccessSummary from './pages/SuccessSummary'
import Partner from './pages/Partner'
import DodajPartnerja from './pages/DodajPartnerja'
import Auth from './pages/Auth'
import Pregled from './pages/Pregled'
import NaiPregled from './pages/NaiPregled'
import Unauthenticated from './pages/Unauthenticated'


const App = () => {
  const [idKreiranegaRacuna, setIdKreiranegaRacuna] = useState(null);
  const [user, setUser] = useState(localStorage.getItem('profile'));
  const { setUserId, userId } = useStateContext();
  const naiID = '62f0fbdce27833ed5a1cf916';
  useEffect(() => {
    const user = localStorage.getItem('profile');
    if (user)
      if(verifyUser()){
        setUser(user);
        setUserId(JSON.parse(user).user._id);
      }
  }, [user, setUserId]);

  return (
    <Container maxWidth="xl">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Auth setUser={setUser} />} />

          <Route path="/izdaja" element={user ? <Izdaja user={user} setIdKreiranegaRacuna={setIdKreiranegaRacuna}/>: <Unauthenticated />} />

          <Route path="/dodaj-blago" element={user ? <DodajBlago />: <Unauthenticated />} />

          <Route path="/izberi-blago" element={user ? <IzberiBlago />: <Unauthenticated />} />
          <Route path="/izberi-blago/:index" element={user ? <IzberiBlago />: <Unauthenticated />} />


          <Route path="/success" element={user ? <SuccessSummary id={idKreiranegaRacuna} />: <Unauthenticated />} />

          <Route path="/partner" element={user ? <Partner />: <Unauthenticated />} />

          <Route path="/dodaj-partnerja" element={user ? <DodajPartnerja />: <Unauthenticated />} />
          
          <Route path="/pregled/nai" element={user && userId===naiID ? <NaiPregled user={JSON.parse(user)} naiID={naiID} />: <Navigate to='/pregled' replace /> } />
          
          <Route path="/pregled" element={user ? <Pregled />: <Unauthenticated />} />

        </Routes>
      </HashRouter>
    </Container>
  )
}

export default App