import React, { useState, useEffect } from 'react'
import { Typography, Divider } from '@mui/material'
import { calculateTotal, calculateDdvs, calculateTotalPrice } from '../utils/index';
import Pricing from './Pricing'
import { useStateContext } from '../context/ContextProvider';
import '../styles/styles.css'

const Sum = () => {
  const { storitve, zneski, setZneski } = useStateContext();
  //const [zneski, setZneski] = useState({ znesekNeto: 0, znesekBruto: 0, ddvPovzetek: {} });
  // const { storitev, blago, storitve, setSkupajBrezDDV, setSkupajDDV, setDiffDDVs } = useStateContext();
  /*const [totalBrezDDV, setTotalBrezDDV] = useState('0');
  const [totalDDVs, setTotalDDVs] = useState([]);
  const [totalBrezDDVForEveryDDV, setTotalBrezDDVForEveryDDV] = useState([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [totalForEveryDdv, setTotalForEveryDdv] = useState([]);
*/

  /*
    useEffect(() => {
      calculateTotal(storitve, setTotalBrezDDV,storitev, setTotalBrezDDVForEveryDDV, setTotalDDVs);
      calculateDdvs(totalBrezDDVForEveryDDV, totalDDVs, setTotalForEveryDdv, storitve);
      calculateTotalPrice(setTotalNumber, totalForEveryDdv);
      setDiffDDVs(totalDDVs);
      setSkupajBrezDDV(totalBrezDDV);
      setSkupajDDV(totalNumber);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storitev, blago, storitve, totalBrezDDV, totalNumber]);
  
    useEffect(() => {
      calculateTotalPrice(setTotalNumber, totalForEveryDdv);
    }, [totalBrezDDVForEveryDDV, totalForEveryDdv]);
  */

  useEffect(() => {

    let ddvPovzetek = {};
    let znesekNeto = 0
    let znesekBruto = 0

    storitve.forEach(storitev => {
      let stopnjaDDV = Number(storitev.stopnjaDDV.replace(',', '.'))
      const znesekVrsticeBrezDDV = Number((storitev.cena.replace(',', '.') * storitev.kolicina.replace(',', '.')).toFixed(2)) // v vrsticah imamo zaokroženo na 2 decimalki, zato tudi tle.
      const ddvVrstice = znesekVrsticeBrezDDV * storitev.stopnjaDDV.replace(',', '.') / 100
      znesekNeto += znesekVrsticeBrezDDV

      if (ddvPovzetek[stopnjaDDV] === undefined)
        ddvPovzetek[stopnjaDDV] = ddvVrstice;
      else
        ddvPovzetek[stopnjaDDV] += ddvVrstice;

    });

    //da ne pride do napake pri zaokroževanju, naredimo to na koncu, ko so zneski sešteti
    znesekBruto = znesekNeto + Object.values(ddvPovzetek).reduce((acc, curr) => acc + Number(curr.toFixed(2)), 0);

    setZneski({ znesekNeto, znesekBruto, ddvPovzetek })

  }, [storitve]);

  const f = (amount) => {
    if (amount)
      return amount.toFixed(2).replace('.', ',')
    else
      return '0,00'
  }

  return (
    <div className="container">
      <div className="pricing">
        <Typography variant='body2'>Skupaj brez DDV </Typography>
        <Typography variant='body2'>{f(zneski.znesekNeto)} €</Typography>
      </div>
      {Object.entries(zneski.ddvPovzetek).map(([ddvStopnja, vrednost]) => (
        <div className="pricing" key={ddvStopnja}>
          <Typography variant='body2'>Obračun za DDV {ddvStopnja.toString().replace('.', ',')} % </Typography>
          <Typography variant='body2'>{f(vrednost)} €</Typography>
        </div>
      ))}

      <Divider sx={{ borderBottomWidth: 2, borderColor: '#ADD8E6', width: '100%', marginTop: '.5rem', marginBottom: '.4rem' }} />
      <div className="pricing">
        <Typography variant='button' fontWeight={600}>SKUPAJ ZA PLAČILO </Typography>
        <Typography variant='button' fontWeight={600}>{f(zneski.znesekBruto)} €</Typography>
      </div>
    </div>
  )
}



/*
{totalDDVs.map((stopnjaDDV, index) => {
        stopnjaDDV = stopnjaDDV.replace(',', '.');
        stopnjaDDV = Number(stopnjaDDV).toFixed(2);
        return (
          <Pricing key={index} name={"Obračun za DDV " + stopnjaDDV.replace('.', ',') + "% :"} ddv={stopnjaDDV} Zddv={totalForEveryDdv} />
        )
      })}
*/

export default Sum