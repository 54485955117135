import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box,Button, Paper, Typography, TextField, Divider, Grid } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { getNaiData } from '../actions/nai';
import { calcNumOfZeros } from '../utils/index';
import { getRacuniCount, createRacun } from '../actions/racuni';
import { formatter } from '../utils/index';
import { CircularProgress } from '@mui/material';
import { getNaiDataById, getAlreadySent, getDataByDate } from '../actions/nai';
import { checkOznacene } from '../utils/index';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import toast, { Toaster } from 'react-hot-toast';
import { useStateContext } from '../context/ContextProvider';
import { generatePdf } from '../actions/racuni'

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    width: 70
  },
  {
    field: 'datum_narocilo',
    headerName: 'Datum narocila',
    width: 120
  },
  {
    field: 'datum_rok',
    headerName: 'Datum roka',
    width: 110
  },
  {
    field: 'datum_ogled',
    headerName: 'Datum ogleda',
    width: 125,
    hide: true
  },
  {
    field: 'sys_date_create',
    headerName: 'Datum ustvarjanja',
    hide: true,
    width: 110
  },
  {
    field: 'datum_ocena',
    headerName: 'Datum ocene',
    width: 125,
    hide: true
  },
  {
    field: 'komitent',
    headerName: 'Komitent',
    width: 100
  },
  {
    field: 'id_sys_upor_ocenjevalec',
    headerName: 'ID ocen.',
    description: 'ID ocenjevalca',
    width: 90
  },
  {
    field: 'naslov',
    headerName: 'Naslov',
    width: 200,
  },
  {
    field: 'kraj',
    headerName: 'Kraj',
    width: 150
  },
  {
    field: 'lat_lng',
    headerName: 'Lat/Lng',
    width: 130,
    hide: true
  },
  {
    field: 'kontakt',
    headerName: 'Kontakt',
    width: 130
  },
  {
    field: 'kontakt_telefon',
    headerName: 'Kontakt telefon',
    width: 130
  },
  {
    field: 'povrsina',
    headerName: 'Površina',
    width: 100
  },
  {
    field: 'cena',
    headerName: 'Cena storitve',
    width: 100
  },
  {
    field: 'priceWithVat',
    headerName: 'Cena storitve z DDV',
    width: 130,
    hide: true
  },
  {
    field: 'marketValue',
    headerName: 'Market value',
    width: 120
  },
  {
    field: 'idsSent',
    headerName: 'ID-ji poslanih',
    width: 10,
    hide: true
  },
  {
    field: 'status',
    headerName: 'Izdano',
    width: 100,
    renderCell: (rowData) => {
      return (
        <Box component='button' sx={{ backgroundColor: 'inherit', border: 'none', "&:hover":{ cursor:'pointer' } }}>
          {rowData.row.idsSent.includes(rowData.id) ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
        </Box>
      )
    }
  }
];

export default function DataGridDemo({ user, naiID }) {
  const { userId } = useStateContext();
  const navigate = useNavigate();
  const [naiData, setNaiData] = useState([]);
  const [modifiedNaiData, setModifiedNaiData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedWithObj, setSelectedWithObj] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [dateSpan, setDateSpan] = useState({ od: '', do: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [numOfRacun, setNumOfRacun] = useState(0);
  const [stRacuna, setStRacuna] = useState(0);
  const [idsSent, setIdsSent] = useState([]);

  const partner = {
    sifra:'0',
    naziv:'NLB d.d.',
    naslov:'Trg republike 2',
    mesto:'Ljubljana',
    posta:'1000',
    oznakaDrzave:'SI',
    TRRracun:'SI56010000000200097',
    davcnaStevilka:'SI91132550'
  }

  useEffect(() => {
    if(userId !== naiID){
      navigate('/pregled');
    }
    setIsLoading(true);
    getRacuniCount(setNumOfRacun);
    getNaiData().then(data => setNaiData(data)).then(() => setIsLoading(false));
    getAlreadySent().then(data => setIdsSent(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let date = new Date().getFullYear().toString().slice(-2);
    setStRacuna(`${date}-${calcNumOfZeros(numOfRacun)}`);
  }, [numOfRacun])

  useEffect(() => {
    setModifiedNaiData(naiData.map((cenitev) => {
      return {
        ...cenitev,
        datum_narocilo: moment(cenitev.datum_narocilo).format('DD.MM.YYYY'),
        datum_rok: moment(cenitev.datum_rok).format('DD.MM.YYYY'),
        datum_ogled: moment(cenitev.datum_ogled).format('DD.MM.YYYY'),
        datum_ocena: moment(cenitev.datum_ocena).format('DD.MM.YYYY'),
        sys_date_create: moment(cenitev.sys_date_create).format('DD.MM.YYYY'),
        marketValue: formatter.format(cenitev.marketValue),
        idsSent: idsSent
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naiData]);

  useEffect(() => {
    setFilteredData(modifiedNaiData);
  }, [modifiedNaiData]);

  const currentlySelected = (selections) => setSelectedData(selections);

  useEffect(() => {
    if(selectedData.length > 0) {
      const selectedWithObj = selectedData.map((id) => {
        return filteredData.find((cenitev) => cenitev.id === id);
      });
      setSelectedWithObj(selectedWithObj);
    }
  }, [selectedData, filteredData]);

  useEffect(() => {
    console.log(selectedWithObj);
  }, [selectedWithObj]);

  const clear = () => setDateSpan({ od: '', do: '' });

  const handleFilter = () => {
    if(dateSpan.od !== '' && dateSpan.do !== '') {
      getDataByDate(dateSpan.od, dateSpan.do).then(data => setNaiData(data));
      setIsFiltersOpen(false);
      clear();
    }
    else {
      setFilteredData(modifiedNaiData);
      setIsFiltersOpen(false);
      clear();
    }
  }

  const handleSendXML = (createdRacunID) => {
    const idString = selectedData.map((id) => id).join(',');
    const prejemnik = prompt('vnesite email naslov prejemnika');
    getNaiDataById(idString, prejemnik, createdRacunID);
  }

  const handleResetFilter = () => {
    getNaiData().then(data => setNaiData(data)).then(() => setIsLoading(false));
    clear();
  }

  const createRacunHandle = async() => {
    const check = checkOznacene(selectedData, idsSent);
    let clearToContinue = false;
    let confirmation;
    let selectedPrice = 0;
    let selectedPriceDDV = 0;

    if(check.hasAlreadySent) {
      alert(`Izbrali ste cenitve, ki so bile že izdane: ID:[${check.selectedButSent}]`);
      confirmation = window.confirm(`Ali želite nadaljevati?`);
      if(confirmation)
        clearToContinue = true;
      else
        clearToContinue = false;
    }
    else {
      clearToContinue = true;
    }
    filteredData.forEach((cenitev) => {
      if(selectedData.includes(cenitev.id)) {
        selectedPrice +=cenitev.cena;
        selectedPriceDDV += cenitev.priceWithVat;
      }
    })
    selectedPrice = formatter.format(selectedPrice).slice(0, -1);
    selectedPriceDDV = formatter.format(selectedPriceDDV).slice(0, -1);


    if(selectedData.length !== 0 && clearToContinue) {
      const racunData = {
        stRacuna: stRacuna ,
        partner: partner,
        datumIzdaje: moment(new Date()).format('DD.MM.YYYY'),
        datumOprStoritve: moment(new Date()).format('DD.MM.YYYY'),
        rokPlacila: moment(new Date()).add(16,'days').format('DD.MM.YYYY'),
        stNarocila: stRacuna,
        diffDDVs: ['22'],
        storitve: [selectedWithObj.map((cenitev) => {
          return {
            id: cenitev.id,
            naziv: `${cenitev.komitent} - ${cenitev.naslov}, ${cenitev.kraj} (${cenitev.datum_narocilo})`,
            cena: cenitev.cena,
            cenaDDV: cenitev.priceWithVat,
            stopnjaDDV: 22,
            enota: "EUR",
            kolicina: 1,
          }
        })],
        skupajBrezDDV: selectedPrice,
        skupajZaPlacilo: selectedPriceDDV,
        isNai: true
      }
      const res = createRacun(racunData);
      res.then(async(data) => { await generatePdf(data._id);handleSendXML(data._id)}).finally(() => window.location.reload());
    }
    else {
      clearToContinue === true ? toast.error('Izbrana mora biti vsaj ena cenitev') :
      toast.error('Potrdilo zavrnjeno');
    }
  }

  return (
    <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column' ,marginTop:'4rem' }}>
      <Toaster />
      {isFiltersOpen ?
        <Box>
          <Paper elevation={6} sx={{ borderRadius:'12px' }}>
            <Grid container spacing={2} sx={{ padding:'1rem', display:'flex', alignItems:'center' }}>
              <Grid item sm={12} xs={12}>
                <Typography variant='body1' fontSize={30} fontWeight={700} >Filtriraj cenitve</Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 2 }} />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography variant='body1' fontSize={20} fontWeight={600}>Datumsko območje cenitev:</Typography>
              </Grid>
              <Grid item sm={6} xs={6} sx={{ display:'flex', alignItems:'center' }}>
                <Typography variant='body1' fontSize={18} sx={{ marginRight:'1rem' }}>Od:</Typography>
                <TextField variant='outlined' type="date" onChange={(e) => setDateSpan({...dateSpan, od:e.target.value})} value={dateSpan.od} />
              </Grid>
              <Grid item sm={6} xs={6} sx={{ display:'flex', alignItems:'center', justifyContent:'flex-end' }}>
                <Typography variant='body1' fontSize={18} sx={{ marginRight:'1rem' }}>Do:</Typography>
                <TextField variant='outlined' type="date" onChange={(e) => setDateSpan({...dateSpan, do:e.target.value})} value={dateSpan.do} />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 2 }} />
              </Grid>
              <Grid item sm ={12} xs={12} sx={{ display:'flex', justifyContent:'space-between', marginTop:'1rem' }}>
                <Button variant='contained' onClick={handleFilter}>Filtriraj</Button>
                <Button variant='contained' color='secondary' onClick={() => {setIsFiltersOpen((prev) => !prev);handleResetFilter()}}>Zapri in počisti</Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        :
        <Box sx={{ display:'flex', justifyContent:'space-between', width:'100%' }}>
          <Button variant='contained' onClick={() => setIsFiltersOpen((prev) => !prev)}>Filtriraj</Button>
          <Button variant='contained' color='secondary' onClick={() => navigate('/pregled')}>Oddani računi</Button>
        </Box>
      }
      <Box sx={{ height: 650, width: '100%', marginTop:'2rem' }}>
        <DataGrid
          rows={filteredData}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          loading={isLoading}
          LoadingOverlay={<CircularProgress />}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={currentlySelected}
        />
        <Box sx={{ display:'flex', justifyContent:'space-between', margin:'2rem 0 1rem 0 ' }}>
          <Button variant="contained" color="primary" onClick={createRacunHandle} startIcon={<ReceiptIcon/>}>Naredi racun</Button>
          <Button variant="contained" color="secondary" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon/>}>Nazaj</Button>
        </Box>
      </Box>
    </Box>
  );
}