import * as api from '../api/index'

export const signin = async (form) => {

    try {
        const { data } = await api.signin(form);

        localStorage.setItem('profile', JSON.stringify(data));

        return true;  
    } catch (error) {
        return false;
    }
    
}

export const verifyUser = async () => {
    try {
        const { data } = await api.verifyUser();

        if(data.status === 'ok') {
            return true;
        } else{
            localStorage.removeItem('profile');
            return false;
        }
    } catch (error) {
        alert('Token outdated');
        localStorage.removeItem('profile');
        window.location.href = '/';
        return false;
    }
}

export const updateLastSeen = async () => {
    try {
        const { data } = await api.updateLastSeen();
        const { token } = JSON.parse(localStorage.getItem('profile'));
        if(data.status === 'ok') {

            localStorage.setItem('profile', JSON.stringify({user:data.user, token}));
        }
    } catch (error) {
        
    }
}