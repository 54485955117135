import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Popup = ({ storitev, setIsSelected }) => {
  const { cena, enota, kolicina, naziv, opis, sifra, stopnjaDDV } = storitev;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsSelected(false);
  };

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} sx={{fontWeight:'600'}}>
          Šifra: {sifra}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button" >Naziv:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{naziv}</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
            </Grid>
            <Grid item sm={12} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button">Opis:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{opis}</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button">Količina:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{kolicina}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button">Enota:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{enota}</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button">Cena:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{cena}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row' }}>
                <Typography variant="button">Stopnja DDV:</Typography>
                <Typography variant="body1" sx={{ fontWeight:'600' }}>{stopnjaDDV}%</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default Popup