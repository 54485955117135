import { useEffect, useState } from 'react';
//import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { Grow, Box, Grid, Typography, TextField, Button, Divider, ThemeProvider, Autocomplete, useMediaQuery } from '@mui/material'

import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { IoMdArrowBack } from 'react-icons/io'
import { CPaper, customColors } from '../styles/themes'
import Modal from '@mui/material/Modal';
import React, { useRef, useImperativeHandle } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getPartnerji, createPartner } from '../actions/partnerji';

const IzberiPartnerja = React.forwardRef((props, ref) => {
  const size = useMediaQuery('(max-width:600px)'); //phone 
  // let navigate = useNavigate();

  //const { storitev, setStoritev, storitve, setStoritve, blago, setBlago } = useStateContext();
  //const [selectedStoritev, setSelectedStoritev] = useState({ id: storitve.length, naziv: '', kolicina: '', enota: '', cena: '', stopnjaDDV: '', opis: '' });
  const paperStyle = { width: size ? '90%' : '500px', padding: '1.5rem' };

  const [regItemChanged, setRegItemChanged] = useState(false);
  const [index, setIndex] = useState(-1);
  const [regSelectBoxVal, setRegSelectBoxVal] = useState(null);

  const regProps = ['naziv', 'naslov', 'mesto', 'posta', 'drzava', 'davcnaStevilka']
  const { partnerji, setPartnerji, setSelectedPartner } = useStateContext();
  const [trenutniPartner, setTrenutniPartner] = useState({ naziv: '', naslov: '', mesto: '', posta: '', drzava: '', davcnaStevilka: '' });

  const clear = () => {
    setTrenutniPartner({ naziv: '', naslov: '', mesto: '', posta: '', drzava: '', davcnaStevilka: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  }

  const checkValidity = () => {
    const myForm = document.getElementById('formBlago');
    const formInputs = myForm.querySelectorAll('input');

    let formValid = true;
    let fe = { ...formErrors }

    formInputs.forEach(input => {

      if (input.name && input.name.length > 0) {
        let v = input.checkValidity()
        fe[input.name] = !v
        if (!v) formValid = false;
      }
    });
    setFormErrors(fe);

    return formValid
  }


  const potrdi = () => {

    if (!checkValidity()) return

    /*if (index >= 0) {
      const updatedStoritve = [...storitve];
      updatedStoritve[index] = storitev;
      setStoritve(updatedStoritve);
    }
    else
      setStoritve([...storitve, storitev]);*/

    setSelectedPartner({ ...trenutniPartner })

    if (checked) {
       
      const newObj = regProps.reduce((acc, prop) => {
        if (trenutniPartner.hasOwnProperty(prop)) {
          acc[prop] = trenutniPartner[prop];
        }
        return acc;
      }, {});

      if (!regSelectBoxVal) {
        //dodaj V REGISTER
        console.log('dodaj V REGISTER')
        createPartner(newObj)
      }
      else if (regItemChanged) {
        //posodobi REGISTER
        console.log('posodobi REGISTER')
        newObj.id = regSelectBoxVal._id
        console.log(newObj)
        createPartner(newObj)      
      }
    }


    handleClose()

  }

  const fillFromSelectBoxChange = (o) => {

    setRegSelectBoxVal(o)
    //setAddUpdateReg(false)

    if (o === null) {
      clear();
      resetFormErrors()
    }
    else {
      setTrenutniPartner({ ...o })
      //setStoritev({ ...o, kolicina: storitev.kolicina });
      resetFormErrors()
    }
  }



  useEffect(() => {
    console.log(trenutniPartner)
    if (regItemChanged)
      setRegItemChanged(false)
    if (regSelectBoxVal) {
      for (let i = 0; i < regProps.length; i++) {
        const prop = regProps[i];
        // console.log(regSelectBoxVal[prop])
        if (regSelectBoxVal[prop] != trenutniPartner[prop]) {
          setRegItemChanged(true)
          break
        }
      };
    }
  }, [trenutniPartner]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (i) => {

    //setIndex(i)
    //getBlago(setBlago);
    clear()
    getPartnerji(setPartnerji);
    resetFormErrors()
    setRegSelectBoxVal(false)

    /*if (i >= 0)
      setStoritev(storitve[i]);
    else
      clear();*/

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen
  }));


  const [checked, setChecked] = React.useState(true);
  const handleChChange = (event) => {
    setChecked(event.target.checked);
  };


  /*setTimeout(function(){
    handleOpen()
  },2000);*/
  const mojstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size ? '90%' : '500px',

    border: '0',
    padding: '1.8rem',
    outline: 'none'
  };

 
  const resetFormErrors = () => {
    setFormErrors({
      naziv: false,
      naslov: false,
      mesto: false,
      posta: false,
      davcnaStevilka: false,
    })
  }
  const [formErrors, setFormErrors] = useState({
    naziv: false,
    naslov: false,
    mesto: false,
    posta: false,
    davcnaStevilka: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name) {
      setFormErrors({
        ...formErrors,
        [name]: !event.target.checkValidity()
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >

      <CPaper elevation={24} sx={mojstyle}>
        <form id="formBlago" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item sm={12} xs={12}>
              <Typography variant="button" fontWeight={600} color={'#969696'} fontSize={'0.75rem'} letterSpacing={'0.7px'}>Izberi partnerja</Typography>
            </Grid>



            <Grid item sm={12} xs={12} style={{ marginBottom: '10px', display: index >= 0 ? 'none' : 'block' }}>
              <Autocomplete options={partnerji} getOptionLabel={option => option.naziv} renderInput={params => <TextField {...params} label="Izberi iz registra" variant="standard" fullWidth />} onChange={(event, value) => { fillFromSelectBoxChange(value) }} />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField variant='standard' size='small' label="Naziv" error={formErrors.naziv} name="naziv" InputProps={{readOnly: (trenutniPartner.vir) }}  onChange={handleChange} fullWidth value={trenutniPartner.naziv} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, naziv: e.target.value })} required />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField variant='standard' size='small' label="Naslov" error={formErrors.naslov} name="naslov"InputProps={{readOnly: (trenutniPartner.vir) }} onChange={handleChange} fullWidth value={trenutniPartner.naslov} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, naslov: e.target.value })} required />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField variant='standard' size='small' label="Davčna Številka"  name="davcnaStevilka" InputProps={{readOnly: (trenutniPartner.vir) }} onChange={handleChange} fullWidth value={trenutniPartner.davcnaStevilka} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, davcnaStevilka: e.target.value })} />
            </Grid>

            <Grid item sm={4} xs={12}>
              <TextField variant='standard' size='small' label="Poštna številka" error={formErrors.posta} name="posta" InputProps={{readOnly: (trenutniPartner.vir) }} onChange={handleChange} fullWidth value={trenutniPartner.posta} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, posta: e.target.value })} required />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField variant='standard' size='small' label="Mesto" error={formErrors.mesto} name="mesto" InputProps={{readOnly: (trenutniPartner.vir) }} onChange={handleChange} fullWidth value={trenutniPartner.mesto} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, mesto: e.target.value })} required />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField variant='standard' size='small' label="Država" error={formErrors.drzava} name="drzava" InputProps={{readOnly: (trenutniPartner.vir) }} onChange={handleChange} fullWidth value={trenutniPartner.drzava} onInput={(e) => setTrenutniPartner({ ...trenutniPartner, drzava: e.target.value })}  />
            </Grid>

            

          </Grid>
        </form>
        <div className="bottomBtns" style={{marginTop:'3rem'}}>
          <Button form="form" variant="contained" color="primary" size="medium" onClick={potrdi} >{index >= 0 ? 'Shrani' : 'Potrdi'}</Button>

          <div style={{ fontSize:'0.8rem', fontWeight: '600', display: trenutniPartner.vir? 'block' : 'none' }} ><span>vir:  &nbsp;</span><span>{trenutniPartner.vir}</span></div>
 
          <FormControlLabel style={{ color: '#6D6D6D', display: index == -1 && (!regSelectBoxVal || regSelectBoxVal && regItemChanged) ? 'inline-flex' : 'none' }} labelPlacement="start" control={<Checkbox size="medium" checked={checked} onChange={handleChChange} />} label={regSelectBoxVal ? "Posodobi register" : "Dodaj v register"} />
        </div>
      </CPaper>

    </Modal>
  )
})

export default IzberiPartnerja

/*
 <Button variant="contained" color="secondary" size="medium" onClick={() => navigate('/')}>Nazaj</Button>
  */
/*
 <Grid item sm={12} xs={12}>
              <Button variant="text" color="primary" size="small" startIcon={<AiOutlinePlus />} onClick={() => navigate("../dodaj-blago")}>Dodaj novo</Button>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor: '#ADD8E6' }} />
            </Grid>
*/