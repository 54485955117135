import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import toast, { Toaster } from 'react-hot-toast';
import { sendMail } from '../actions/mail';


const FormDialog = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState(null);
    //let _id = null
    const handleClickOpen = (_id) => {
        console.log(_id)
        setId(_id)
        setOpen(true);
        console.log(id)
    };

    const handleClose = () => {
        setId(null)
        setOpen(false);
    };

    React.useImperativeHandle(ref, () => ({
        handleClickOpen: handleClickOpen, // Metoda, ki jo želimo izpostaviti parent komponenti
        // handleClose: handleClose, // Če bi želeli tudi to metodo izpostaviti
    }));

    /*
    <Button variant="outlined" onClick={handleClickOpen}>
          Open form dialog
        </Button> */

    return (
        <React.Fragment>
            <Toaster />
            <Dialog 
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        console.log(id)
                        if (id) {
                            sendMail(id, email).then(res => {
                                console.log(res)
                                toast.success('email uspešno poslan', {
                                    style: { padding: '16px', fontWeight: 600, }
                                });
                            })
                                .catch(e => {
                                    console.error(e)
                                    toast.error('Napaka pri pošiljanju.', {
                                        iconTheme: {
                                            primary: '#552600',
                                            secondary: '#FFFAEE',
                                        },
                                        style: {
                                            border: '1px solid #552600',
                                            padding: '16px',
                                            fontWeight: 600,
                                            color: '#552600'
                                        }
                                    });
                                })
                        }
                        else console.log('ni idja, email...')

                        handleClose();
                    },
                }}
            >
                <DialogTitle style={{width:'300px'}}>Pošlji račun na email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Prekliči</Button>
                    <Button type="submit">Pošlji</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});

export default FormDialog;