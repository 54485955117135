import * as api from '../api/index.js';

export const sendMail = async(id, recipient) => {
    try {
        const response = await api.sendMail(id, recipient);
        
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const sendMails = async (selectedString, recipient) => {
    try {
        const response = await api.sendMails(selectedString, recipient);
        return response;
    } catch (error) {
        console.log(error);
    }
}

