import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'

const IzbranoBlago = ({ naziv, opis, kolicina, cena, stopnjaDDV, bg }) => {
  stopnjaDDV = Number(stopnjaDDV.replace(',','.')).toString().replace('.', ',');
  const size = useMediaQuery('(max-width:600px)'); //phone 
  const cenaBrezDDV = parseFloat(kolicina.replace(',','.')) * parseFloat(cena.replace(',','.'));
  const GridStyle = { backgroundColor:bg, marginLeft:'0', marginBottom:'6px', display:'flex', justifyContent:'space-between', width: '100%', borderRadius: '3px'}
  return (
    <Grid container spacing={0} sx={GridStyle}>
      <Grid item sm={12} xs={12} style={{paddingLeft:'15px', paddingTop:'11px', paddingBottom:'5px'}}>
        <Typography variant='caption' fontWeight={600} style={{fontSize:'0.8rem'}}>{naziv}</Typography>
      </Grid>
      <Grid item sm={3} style={{paddingLeft:'15px', paddingBottom:'9px'}}>
        <Typography variant='caption' >{kolicina}</Typography>
      </Grid>
      <Grid item sm={3} style={{ paddingBottom:'9px'}}>
        <Typography variant='caption' sx={!size?{ marginLeft:'3px' }:{ marginLeft:'33px' }}>{cena} €</Typography>
      </Grid>
      <Grid item sm={3} style={{paddingBottom:'9px'}}>
        <Typography variant='caption' sx={!size?{ marginLeft:'6px' }:{ marginLeft:'1px' }}>{stopnjaDDV} %</Typography>
      </Grid>
      <Grid item sm={3} style={{paddingBottom:'9px', paddingRight:'15px'}}>
        <Typography variant='caption' sx={!size?{ marginLeft:'7px' }:{  }}>{cenaBrezDDV.toFixed(2).replace('.',',')}</Typography>
      </Grid>
    </Grid>
  )
}

export default IzbranoBlago