import * as api from '../api/index';
import buffer from 'buffer';

export const getPdf = async (stRacuna, creator, id, download) => {
    try {
        const { data } = await api.sendPdf(stRacuna, creator, id);
        //change the base64 data to a arraybuffer
        const bufferr = buffer.Buffer.from(data, 'base64');

        const newBlob = new Blob([bufferr], { type: 'application/pdf' });
        //download the file
        const url = window.URL.createObjectURL(newBlob);
        
        if (download) {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${stRacuna}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
        else
            window.open(url, '_blank');

    } catch (error) {
        console.log(error);
    }
}