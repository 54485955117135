import * as api from '../api/index';
 
export const getBlago = async (setBlago) => {
    try {
        const { data } = await api.getBlago();
        setBlago(data);
    } catch (error) {
        console.log(error);
    }
}

export const createBlago = async (newBlago) => {
    try {
        await api.createBlago(newBlago);

    } catch (error) {
        console.log(error);
    }
        
}