import axios from 'axios';

//test:
//const API = axios.create({ baseURL: 'http://localhost:5000/' }); /* https://mojarhiv.net:8027/ || http://localhost:5000/ */

//prod:
const API = axios.create({ baseURL: 'https://softracuni.mojarhiv.net/api/' }); /* https://mojarhiv.net:8027/ || http://localhost:5000/ */


API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }

    return req;
});

export const getRacuni = () => API.get('/racuni/');
export const getRacuniTop = (nr, type) => API.get(`/racuni/TOP/${nr}/${type}`);
export const getRacun = (id) => API.get(`/racuni/${id}`);
export const deleteRacun = (id) => API.delete(`/racuni/${id}`);

export const createRacun = (racun) => API.post('/racuni/', racun);
export const getRacuniCount = (year) => API.get(`/racuni/count/${year}`);

export const getBlago = () => API.get('/blago/');
export const getOneBlago = (id) => API.get(`/blago/${id}`);
export const createBlago = (blago) => API.post('/blago/', blago);

export const getPartner = () => API.get('/partner/');
export const getOnePartner = (id) => API.get(`/partner/${id}`);
export const createPartner = (partner) => API.post('/partner/', partner);

export const getNaiData = () => API.get('/naisql/');
export const sendNaiIds = (ids, prejemnik, createdRacunID) => API.post(`/naisql/?ids=${ids}`, { prejemnik, createdRacunID });
export const getDataByDate = (from, to) => API.get(`/naisql/date?from=${from}&to=${to}`);

export const genPdf = (id) => API.get(`/pdf/${id}`);
export const sendPdf = (stRacuna, creator, id) => API.get(`/pdf/getPdf/${stRacuna}/${creator}/${id}`);

export const sendMail = (id,recipient) => API.post(`/mail/${id}/${recipient}`);
export const sendMails = (selectedString, recipient) => API.post(`/mail/sendMails/?selectedString=${selectedString}&recipient=${recipient}`);

export const alreadySent = () => API.get('/naisql/alreadySent');

export const signin = (form) => API.post('user/signin', form);
export const updateLastSeen = () => API.put('user/lastseen');

export const verifyUser = () => API.post('user/verify');