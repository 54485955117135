import * as api from '../api/index.js';
 
export const getPartnerji = async (setPartnerji) => {
    try {
        const { data } = await api.getPartner();
        setPartnerji(data);
    } catch (error) {
        console.log(error);
    }
}

export const createPartner = async (newPartner) => {
    try {
        await api.createPartner(newPartner);

    } catch (error) {
        console.log(error);
    }
        
}