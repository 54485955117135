import React, { useState, useEffect } from 'react'
import { Grow, Box, Grid, Typography, TextField, Button, ThemeProvider, useMediaQuery } from '@mui/material'
import { CPaper, customColors } from '../styles/themes'
import { IoMdArrowBack } from 'react-icons/io'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { createBlago } from '../actions/blago'

const Dodajblago = () => {
  const size = useMediaQuery('(max-width:600px)'); //phone 
  let navigate = useNavigate();
  const { blago, setBlago } = useStateContext();
  const [sifra, setSifra] = useState(blago.length);
  const paperStyle = { width: size ? '90%' : '70%', padding: '1.5rem' };
  const [trenutnoBlago, setTrenutnoBlago] = useState({ sifra:blago.length, naziv:'', enota:'', cena:'', stopnjaDDV:'', opis:'' });
  const clear = () => setTrenutnoBlago({ sifra:blago.length, naziv:'', enota:'', cena:'', stopnjaDDV:'', opis:'' });

  const handleSubmit = (e) =>{
    e.preventDefault();
    createBlago(trenutnoBlago);
    setTrenutnoBlago({...trenutnoBlago, sifra: blago.length});
    setBlago([...blago, trenutnoBlago]);
    navigate('/izberi-blago');
  }

  useEffect(() => {
    setSifra(blago.length);
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Grow in>
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop:'8rem' }}>
        <CPaper elevation={24} sx={paperStyle}>
          <form id="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item sm={12} xs={12}>
                <Typography variant="button" fontWeight={600} >Blago/Storitev</Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Šifra" inputProps={{ readOnly: true, style:{fontWeight:'600', marginLeft:'10px'} }} value={sifra} fullWidth required />
              </Grid>

              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Ime/Naziv" fullWidth onChange={(e) => setTrenutnoBlago({...trenutnoBlago, naziv: e.target.value}) } required /> 
              </Grid>

              <Grid item sm={12} xs={12}>
                <TextField variant='standard' size='small' label="Opis" multiline rows={4} fullWidth onChange={(e) => setTrenutnoBlago({...trenutnoBlago, opis: e.target.value}) } required />
              </Grid>

              <Grid item xs={4} lg={4}>
                <TextField variant='standard' size='small' label="Enota" fullWidth onChange={(e) => setTrenutnoBlago({...trenutnoBlago, enota: (e.target.value).toLowerCase()}) } required />
              </Grid>
              <Grid item xs={4} lg={4}>
                <TextField variant='standard' size='small' label="Cena" fullWidth onChange={(e) => setTrenutnoBlago({...trenutnoBlago, cena: e.target.value}) } required />
              </Grid>
              <Grid item xs={4} lg={4}>
                <TextField variant='standard' size='small' label="Stopnja DDV" fullWidth onChange={(e) => setTrenutnoBlago({...trenutnoBlago, stopnjaDDV: e.target.value}) } required />
              </Grid>
            </Grid>
          </form>
          <div className="bottomBtns">
            <ThemeProvider theme={customColors} >
              <Button form="form" variant="contained" color="primary" size="small" type="submit" startIcon={<AiOutlinePlus />}>Shrani</Button>
              <Button variant="contained" color="secondary" size="small" startIcon={<IoMdArrowBack />} onClick={() => navigate(-1)}>Nazaj</Button>
            </ThemeProvider>
          </div>
        </CPaper>
      </Box>
    </Grow>
  )
}

export default Dodajblago